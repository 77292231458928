export const yupStandardErrorMessages = {
  mixed: {
    default: 'Champ non valide',
    required: 'Champ requis',
    notType: 'Champ non valide',
  },
  string: {
    max: 'Doit contenir moins de ${max} caractères',
    min: 'Doit contenir plus de ${min} caractères',
    url: 'URL non valide',
    email: 'Email non valide',
    matches: 'Champ non valide',
  },
  number: {
    integer: 'Doit être un nombre entier',
    moreThan: 'Doit être supérieur à ${more}',
    lessThan: 'Doit être inférieur à ${less}',
    min: 'Doit être supérieur ou égal à ${min}',
  },
};
