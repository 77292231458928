// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"ba161a232c59a4c81780bd896408f49368d4d08d"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { MissingEnvironmentVariableError } from '@core/errors';
import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';
import * as uuid from 'uuid';

const ENV = process.env.NEXT_PUBLIC_ENV;
const IS_PRODUCTION = ENV === 'production';
const IS_DEV = ENV === 'development';

const VERCEL_GIT_COMMIT_SHA =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ??
  process.env.VERCEL_GIT_COMMIT_SHA;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ORG = process.env.SENTRY_ORG;
const IS_SENTRY_SESSION_REPLAY_ENABLED =
  process.env.NEXT_PUBLIC_SENTRY_SESSION_REPLAY_ENABLED === 'true';
const IS_SENTRY_ENABLED =
  process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true' &&
  !!VERCEL_GIT_COMMIT_SHA &&
  !!SENTRY_DSN;

const POSTHOG_SENTRY_PROJECT_ID = SENTRY_DSN
  ? new URL(SENTRY_DSN).pathname.split('/')[1]
  : null;
const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;

/**
 * ENV checks
 */
if (!ENV) {
  throw new MissingEnvironmentVariableError({
    envVarName: 'NEXT_PUBLIC_ENV',
  });
}

if (
  !VERCEL_GIT_COMMIT_SHA &&
  process.env.NEXT_PUBLIC_SENTRY_WEBPACK_PLUGIN_ENABLED === 'true'
) {
  throw new MissingEnvironmentVariableError({
    envVarName: 'VERCEL_GIT_COMMIT_SHA',
  });
}

if (!IS_SENTRY_ENABLED) {
  // eslint-disable-next-line no-console
  console.warn(
    '[Observability]: Client-side initialization ignored, service is disabled'
  );
}
/**
 * ENV checks end
 */

const sessionId = uuid.v4();

if (!IS_PRODUCTION) {
  // eslint-disable-next-line no-console
  console.log('[Observability]: Initializing client-side');
}

// Client initialization code will be injected into the application's client bundle by withSentryConfig
Sentry.init({
  enabled: IS_SENTRY_ENABLED,

  dsn: SENTRY_DSN,
  release: VERCEL_GIT_COMMIT_SHA,
  environment: process.env.NEXT_PUBLIC_ENV,

  // Sentry SDKs normalize any contextual data to a given depth. Any data beyond this depth will be trimmed and marked using its type instead
  normalizeDepth: 11,

  // Add optional integrations for additional features
  integrations: !IS_DEV ? [Sentry.browserTracingIntegration()] : [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  enableTracing: true,
  tracesSampleRate: 0.05,
  // TODO: Adding a more granular configuration for tracing later like manual instrumentation for critical paths

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0, // Handled by posthog

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: IS_DEV,

  // Data to be set to the initial scope. Initial scope can be defined either as an object or a callback function.
  initialScope: {
    tags: {
      session_id: sessionId,
    },
  },
});

if (IS_SENTRY_SESSION_REPLAY_ENABLED) {
  if (!IS_PRODUCTION) {
    // eslint-disable-next-line no-console
    console.log('[Observability]: Adding replay integration client-side');
  }
  Sentry.addIntegration(
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    })
  );
} else {
  if (!IS_PRODUCTION) {
    // eslint-disable-next-line no-console
    console.log('[Observability]: Not adding replay integration client-side');
  }
}

if (POSTHOG_KEY && POSTHOG_SENTRY_PROJECT_ID && SENTRY_ORG) {
  if (!IS_PRODUCTION) {
    // eslint-disable-next-line no-console
    console.log('[Observability]: Adding analytics integration client-side');
  }
  Sentry.addIntegration(
    posthog.sentryIntegration({
      organization: SENTRY_ORG,
      projectId: parseInt(POSTHOG_SENTRY_PROJECT_ID, 10),
      severityAllowList: ['warning', 'error'],
    })
  );
} else {
  if (!IS_PRODUCTION) {
    // eslint-disable-next-line no-console
    console.log(
      '[Observability]: Not adding analytics integration client-side'
    );
  }
}
